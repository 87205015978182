import { default as about_45uszUtrvnPM5SMeta } from "/tmp/pages/about-us.vue?macro=true";
import { default as cultureoQwz9WouCTMeta } from "/tmp/pages/culture.vue?macro=true";
import { default as _91id_93sjPEN3YLU2Meta } from "/tmp/pages/events/[id].vue?macro=true";
import { default as indexXxdCXtx8apMeta } from "/tmp/pages/events/index.vue?macro=true";
import { default as indexA7PsDcc7sbMeta } from "/tmp/pages/index.vue?macro=true";
import { default as learningHNo8cWwRTSMeta } from "/tmp/pages/learning.vue?macro=true";
import { default as _91id_933LuMvAr9aMMeta } from "/tmp/pages/materials/[id].vue?macro=true";
import { default as indexHhMupgDTppMeta } from "/tmp/pages/materials/index.vue?macro=true";
import { default as indexvZ70Mk2lloMeta } from "/tmp/pages/offer/[id]/accept/index.vue?macro=true";
import { default as indexdq8y6yg5kqMeta } from "/tmp/pages/offer/[id]/feedback/index.vue?macro=true";
import { default as indexQObQQmxGTNMeta } from "/tmp/pages/offer/[id]/index.vue?macro=true";
import { default as one_45cAyD2loVCFQMeta } from "/tmp/pages/one-c.vue?macro=true";
import { default as _91id_93yhBw0xtg5lMeta } from "/tmp/pages/work/[id].vue?macro=true";
import { default as indexlzbQSamcfxMeta } from "/tmp/pages/work/index.vue?macro=true";
export default [
  {
    name: about_45uszUtrvnPM5SMeta?.name ?? "about-us",
    path: about_45uszUtrvnPM5SMeta?.path ?? "/about-us",
    meta: about_45uszUtrvnPM5SMeta || {},
    alias: about_45uszUtrvnPM5SMeta?.alias || [],
    redirect: about_45uszUtrvnPM5SMeta?.redirect || undefined,
    component: () => import("/tmp/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: cultureoQwz9WouCTMeta?.name ?? "culture",
    path: cultureoQwz9WouCTMeta?.path ?? "/culture",
    meta: cultureoQwz9WouCTMeta || {},
    alias: cultureoQwz9WouCTMeta?.alias || [],
    redirect: cultureoQwz9WouCTMeta?.redirect || undefined,
    component: () => import("/tmp/pages/culture.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sjPEN3YLU2Meta?.name ?? "events-id",
    path: _91id_93sjPEN3YLU2Meta?.path ?? "/events/:id",
    meta: _91id_93sjPEN3YLU2Meta || {},
    alias: _91id_93sjPEN3YLU2Meta?.alias || [],
    redirect: _91id_93sjPEN3YLU2Meta?.redirect || undefined,
    component: () => import("/tmp/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: indexXxdCXtx8apMeta?.name ?? "events",
    path: indexXxdCXtx8apMeta?.path ?? "/events",
    meta: indexXxdCXtx8apMeta || {},
    alias: indexXxdCXtx8apMeta?.alias || [],
    redirect: indexXxdCXtx8apMeta?.redirect || undefined,
    component: () => import("/tmp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexA7PsDcc7sbMeta?.name ?? "index",
    path: indexA7PsDcc7sbMeta?.path ?? "/",
    meta: indexA7PsDcc7sbMeta || {},
    alias: indexA7PsDcc7sbMeta?.alias || [],
    redirect: indexA7PsDcc7sbMeta?.redirect || undefined,
    component: () => import("/tmp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: learningHNo8cWwRTSMeta?.name ?? "learning",
    path: learningHNo8cWwRTSMeta?.path ?? "/learning",
    meta: learningHNo8cWwRTSMeta || {},
    alias: learningHNo8cWwRTSMeta?.alias || [],
    redirect: learningHNo8cWwRTSMeta?.redirect || undefined,
    component: () => import("/tmp/pages/learning.vue").then(m => m.default || m)
  },
  {
    name: _91id_933LuMvAr9aMMeta?.name ?? "materials-id",
    path: _91id_933LuMvAr9aMMeta?.path ?? "/materials/:id",
    meta: _91id_933LuMvAr9aMMeta || {},
    alias: _91id_933LuMvAr9aMMeta?.alias || [],
    redirect: _91id_933LuMvAr9aMMeta?.redirect || undefined,
    component: () => import("/tmp/pages/materials/[id].vue").then(m => m.default || m)
  },
  {
    name: indexHhMupgDTppMeta?.name ?? "materials",
    path: indexHhMupgDTppMeta?.path ?? "/materials",
    meta: indexHhMupgDTppMeta || {},
    alias: indexHhMupgDTppMeta?.alias || [],
    redirect: indexHhMupgDTppMeta?.redirect || undefined,
    component: () => import("/tmp/pages/materials/index.vue").then(m => m.default || m)
  },
  {
    name: indexvZ70Mk2lloMeta?.name ?? "offer-id-accept",
    path: indexvZ70Mk2lloMeta?.path ?? "/offer/:id/accept",
    meta: indexvZ70Mk2lloMeta || {},
    alias: indexvZ70Mk2lloMeta?.alias || [],
    redirect: indexvZ70Mk2lloMeta?.redirect || undefined,
    component: () => import("/tmp/pages/offer/[id]/accept/index.vue").then(m => m.default || m)
  },
  {
    name: indexdq8y6yg5kqMeta?.name ?? "offer-id-feedback",
    path: indexdq8y6yg5kqMeta?.path ?? "/offer/:id/feedback",
    meta: indexdq8y6yg5kqMeta || {},
    alias: indexdq8y6yg5kqMeta?.alias || [],
    redirect: indexdq8y6yg5kqMeta?.redirect || undefined,
    component: () => import("/tmp/pages/offer/[id]/feedback/index.vue").then(m => m.default || m)
  },
  {
    name: indexQObQQmxGTNMeta?.name ?? "offer-id",
    path: indexQObQQmxGTNMeta?.path ?? "/offer/:id",
    meta: indexQObQQmxGTNMeta || {},
    alias: indexQObQQmxGTNMeta?.alias || [],
    redirect: indexQObQQmxGTNMeta?.redirect || undefined,
    component: () => import("/tmp/pages/offer/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: one_45cAyD2loVCFQMeta?.name ?? "one-c",
    path: one_45cAyD2loVCFQMeta?.path ?? "/one-c",
    meta: one_45cAyD2loVCFQMeta || {},
    alias: one_45cAyD2loVCFQMeta?.alias || [],
    redirect: one_45cAyD2loVCFQMeta?.redirect || undefined,
    component: () => import("/tmp/pages/one-c.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yhBw0xtg5lMeta?.name ?? "work-id",
    path: _91id_93yhBw0xtg5lMeta?.path ?? "/work/:id",
    meta: _91id_93yhBw0xtg5lMeta || {},
    alias: _91id_93yhBw0xtg5lMeta?.alias || [],
    redirect: _91id_93yhBw0xtg5lMeta?.redirect || undefined,
    component: () => import("/tmp/pages/work/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlzbQSamcfxMeta?.name ?? "work",
    path: indexlzbQSamcfxMeta?.path ?? "/work",
    meta: indexlzbQSamcfxMeta || {},
    alias: indexlzbQSamcfxMeta?.alias || [],
    redirect: indexlzbQSamcfxMeta?.redirect || undefined,
    component: () => import("/tmp/pages/work/index.vue").then(m => m.default || m)
  }
]