<template>
<div>
	<NuxtLayout>
		<NuxtPage/>
	</NuxtLayout>
</div>
</template>

<script lang="ts">

export default {
	name: "app",
	setup() {}
}
</script>

<style lang="scss">
@import "assets/styles/main";
</style>
